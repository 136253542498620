import {FeedbackType} from "../../types";
import {photo1, photo2, photo3} from "../../assets";

export const feedbackData: FeedbackType[] = [
    {
        pic: photo1,
        name: "Анна Карпенко1",
        department: "Зелений кооператив",
        content: "Моя родина і я вдячні компанії \"Розбудова кооперативів\" за чудовий досвід співпраці. Вони допомогли" +
            " нам зреалізувати нашу мрію про власний будинок. Професійний підхід, висока якість робіт і швидке виконання" +
            " завдань - це те, чому можна довіряти. Рекомендую з усієї душі!"
    },
    {
        pic: photo2,
        name: "Іван Мазейнюк1",
        department: "Спільнота комфорту",
        content: "Приємно співпрацювати з командою \"Розбудова кооперативів\". Вони допомогли нам обрати оптимальні " +
            "рішення для нашого будівництва і професійно втілили наші ідеї в реальність. Результат перевершив наші " +
            "очікування, і ми залишаємось дуже задоволені співпрацею."
    },
    {
        pic: photo3,
        name: "Джонні Деп",
        department: "Зелений кооператив,",
        content: "Моя родина і я вдячні компанії \"Розбудова кооперативів\" за чудовий досвід співпраці. Вони допомогли" +
            " нам зреалізувати нашу мрію про власний будинок. Професійний підхід, висока якість робіт і швидке виконання" +
            " завдань - це те, чому можна довіряти. Рекомендую з усієї душі"
    },
    {
        pic: photo2,
        name: "Іван Мазейнюк2",
        department: "Спільнота комфорту&",
        content: "Приємно співпрацювати з командою \"Розбудова кооперативів\". Вони допомогли нам обрати оптимальні " +
            "рішення для нашого будівництва і професійно втілили наші ідеї в реальність. Результат перевершив наші " +
            "очікування, і ми залишаємось дуже задоволені співпрацею!"
    },
    {
        pic: photo1,
        name: "Анна Карпенко3",
        department: "Зелений кооператив!",
        content: "Моя родина і я вдячні компанії \"Розбудова кооперативів\" за чудовий досвід співпраці. Вони допомогли" +
            " нам зреалізувати нашу мрію про власний будинок. Професійний підхід, висока якість робіт і швидке виконання" +
            " завдань - це те, чому можна довіряти. Рекомендую з усієї душі?"
    },
    {
        pic: photo2,
        name: "Іван Мазейнюк3",
        department: "Спільнота комфорту&",
        content: "Приємно співпрацювати з командою \"Розбудова кооперативів\". Вони допомогли нам обрати оптимальні " +
            "рішення для нашого будівництва і професійно втілили наші ідеї в реальність. Результат перевершив наші " +
            "очікування, і ми залишаємось дуже задоволені співпрацею.?"
    },
    {
        pic: photo1,
        name: "Анна Карпенко4",
        department: "Зелений кооператив,",
        content: "Моя родина і я вдячні компанії \"Розбудова кооперативів\" за чудовий досвід співпраці. Вони допомогли" +
            " нам зреалізувати нашу мрію про власний будинок. Професійний підхід, висока якість робіт і швидке виконання" +
            " завдань - це те, чому можна довіряти. Рекомендую з усієї душі$"
    },
    {
        pic: photo2,
        name: "Іван Мазейнюк4",
        department: "Спільнота комфорту.",
        content: "Приємно співпрацювати з командою \"Розбудова кооперативів\". Вони допомогли нам обрати оптимальні " +
            "рішення для нашого будівництва і професійно втілили наші ідеї в реальність. Результат перевершив наші " +
            "очікування, і ми залишаємось дуже задоволені співпрацею$"
    },
]